var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","depressed":"","color":"primary darken-1","title":"Class Outcomes"}},on),[_c('v-icon',[_vm._v("mdi-book-education")])],1)]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Class Outcomes")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","icon":"","elevation":"2","title":"Close"},on:{"click":function($event){_vm.dialogVisible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-toolbar',{staticClass:"my-4",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('div',{staticClass:"text-overline grey--text"},[_vm._v("Stage & Milestone")]),_c('div',{staticClass:"primary--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.stageAndMilestone)+" ")])])],1),_c('v-card-text',[_c('v-container',{staticClass:"sticky-header",attrs:{"fluid":""}},[_c('v-row',[(_vm.showStrandColumn)?_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('v-sheet',[_c('v-card',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"112px"},attrs:{"color":"accent darken-1"}},[_c('div',{staticClass:"text-button text-center white--text px-3"},[_vm._v(" Strands ")])])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.showStrandColumn ? 9 : 12}},[_c('v-sheet',{},[_c('v-card',{staticClass:"pa-2"},[_c('v-item-group',{staticClass:"mt-2",attrs:{"mandatory":"","active-class":"primary--text"},model:{value:(_vm.activeStageId),callback:function ($$v) {_vm.activeStageId=$$v},expression:"activeStageId"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.stages),function(stage){return _c('v-col',{key:stage.id},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-card',{staticClass:"d-flex align-center stage-card",class:{'active': active, 'white--text': _vm.milestoneDisplayStyle !== 'bordered'},attrs:{"outlined":""}},[_c('div',{staticClass:"text-button px-3 flex-grow-1 text-center",class:{ '': active},style:(_vm.milestoneTabStyle(stage.color))},[_vm._v(" "+_vm._s(stage.title)+" ")])])]}}],null,true)})],1)}),1)],1)],1),_c('v-item-group',{staticClass:"mt-2",attrs:{"mandatory":"","active-class":"primary--text"},model:{value:(_vm.currentMilestoneId),callback:function ($$v) {_vm.currentMilestoneId=$$v},expression:"currentMilestoneId"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.allDevelopmentMilestones),function(developmentMilestone){return _c('v-col',{key:developmentMilestone.id},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center milestone-card",class:{'active': active},attrs:{"outlined":""},on:{"click":toggle}},[_c('div',{staticClass:"text-button px-3 flex-grow-1 text-center",class:{ '': active },style:(_vm.milestoneTabStyle(developmentMilestone.color))},[_vm._v(" "+_vm._s(developmentMilestone.title)+" ")])])]}}],null,true)})],1)}),1)],1)],1)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"pt-0",staticStyle:{"xheight":"100%"},attrs:{"fluid":""}},[_c('v-row',[(_vm.showStrandColumn)?_c('v-col',{attrs:{"cols":"3"}},_vm._l((_vm.strands),function(strand,index){return _c('v-card',{key:strand.id,staticClass:"mb-2",attrs:{"flat":""}},[_c('v-card',{staticClass:"strand-cell",class:{'active': _vm.isSelectedStrand(index)},attrs:{"color":_vm.strandDefinitionsColor}},[_c('v-card-text',{staticClass:"text-subtitle-2 px-3"},[_vm._v(" "+_vm._s(strand.title)+" ")])],1)],1)}),1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.showStrandColumn ? 9 : 12}},[_c('v-tabs-items',{model:{value:(_vm.currentMilestoneId),callback:function ($$v) {_vm.currentMilestoneId=$$v},expression:"currentMilestoneId"}},_vm._l((_vm.allDevelopmentMilestones),function(developmentMilestone){return _c('v-tab-item',{key:developmentMilestone.id},_vm._l((developmentMilestone.strandMilestones),function(strandMilestone,index){return _c('v-expansion-panels',{key:strandMilestone.id,attrs:{"value":_vm.isSelectedStrand(index) ? 0 : -1},on:{"change":function($event){return _vm.selectRow(index)}}},[_c('v-expansion-panel',{staticClass:"mb-2 milestone-cell",style:(_vm.milestoneCellStyle(developmentMilestone))},[_c('v-expansion-panel-header',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"overline"},[_vm._v(" "+_vm._s(strandMilestone.title)+" ")]),_c('div',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(
                            _vm.decodeHtml(strandMilestone.description)
                          )}})])]),_c('v-expansion-panel-content',{attrs:{"color":_vm.milestoneDisplayStyle === 'bordered' ? '' : developmentMilestone.color}},[_c('div',{staticClass:"extra-info text-caption",class:{ active: true, autoheight: !_vm.showStrandColumn},domProps:{"innerHTML":_vm._s(_vm.decodeHtml(strandMilestone.explanation))}})])],1)],1)}),1)}),1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }