











































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
  name: 'ClassOutcomes',
  data: () => ({
    dialogVisible: false
  })
})
export default class ClassOutcomes extends Vue {
  currentStageId: any
  currentMilestoneId: any = 0
  selectedStrands: number[] = []

  @Prop() private classItem!: any

  get devlevels (): any {
    return this.$store.state.devlevels
  }

  get activeStageId (): any {
    return this.currentStageId
  }

  set activeStageId (value: any) {
    this.currentStageId = value
  }

  get allDevelopmentMilestones (): any {
    return this.$store.getters.getStrandDevMilestones()
  }

  get stages (): any {
    return this.$store.getters.getStages()
  }

  get milestones (): any {
    return this.$store.getters.getMilestones()
  }

  get showStrandColumn (): boolean {
    return this.strands.length > 1
  }

  get strands (): any {
    return this.$store.getters.getStrands()
  }

  get strandDefinitionsColor (): string {
    return this.$store.state.programData.strandDefinitionsColor
  }

  get stage (): any {
    return this.$store.getters.getStageOfCurrentClass() || {}
  }

  get milestone (): any {
    return this.$store.getters.getMilestoneOfCurrentClass() || {}
  }

  get milestoneDisplayStyle (): string {
    return this.$store.getters.getConfig('milestoneDisplayStyle', '')
  }

  milestoneTabStyle (color: any): string {
    let styleVal = ''
    switch (this.milestoneDisplayStyle) {
      case 'bordered':
        styleVal = 'border-bottom: solid 8px ' + color + ';border-top: solid 4px transparent;'
        // styleVal = styleVal + (active ? '' : 'background-color: #dddddd;')
        break
      default:
        styleVal = 'background-color:' + color
        break
    }
    return styleVal
  }

  milestoneCellStyle (developmentMilestone: any): string {
    let styleVal = ''
    switch (this.milestoneDisplayStyle) {
      case 'bordered':
        styleVal = 'border-left: solid 8px ' + developmentMilestone.color + ';'
        break
      default:
        styleVal = 'background-color:' + developmentMilestone.color + ';'
        break
    }
    return styleVal
  }

  get stageAndMilestone (): string {
    const titles = []
    if (this.stage.title) {
      titles.push(this.stage.title)
    }
    if (this.milestone.title) {
      titles.push(this.milestone.title)
    }
    const title = _.join(
      titles.filter((i) => i !== ''),
      ' - '
    )
    return title
  }

  get selectedStrandIndexes (): number[] {
    return this.selectedStrands
  }

  set selectedStrandIndexes (value: number[]) {
    this.selectedStrands = value
  }

  selectRow (index: number) {
    if (this.selectedStrandIndexes.indexOf(index) !== -1) {
      // remove the item
      for (let i = 0; i < this.selectedStrands.length; i++) {
        if (this.selectedStrands[i] === index) {
          this.selectedStrands.splice(i, 1)
          i--
        }
      }
      // this.selectedStrandIndexes = _.pull(this.selectedStrandIndexes, index)
      // this.selectedStrandIndexes.splice(0, 0)
    } else {
      // debugger
      this.selectedStrandIndexes.push(index)
      // this.selectedStrands.splice(0, 0)
      // this.selectedStrands.splice(this.selectedStrands.length, 1, index)
    }
    // console.log(this.selectedStrands.toString())
  }

  isSelectedStrand (index: number): boolean {
    // console.log(`${this.selectedStrandIndexes}, index: ${index}`)
    if (!this.showStrandColumn) {
      return true
    }
    const result = this.selectedStrandIndexes.indexOf(index) !== -1
    // console.log(`all: ${this.selectedStrands.toString()}`)
    // console.log(`index: ${index}`)
    return result
  }

  decodeHtml (html: string) {
    return _.unescape(html)
  }

  beforeMount () {
    this.activeStageId = _.findIndex(this.stages, ['id', this.stage.id])
    this.currentMilestoneId = _.findIndex(this.milestones, [
      'id',
      this.milestone.id
    ])
  }
}
