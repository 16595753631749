

































































// import Vue from 'vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Help from '@/components/Help.vue'
import ClassOutcomes from '@/components/ClassOutcomes.vue'
import ClassPdfButton from '@/components/ClassPdfButton.vue'
import _ from 'lodash'
const TARGET_DOMAIN = 'https://josh.canopi.janison.com'
const FRAME_ANCESTORS_WHITELIST = [
  'https://royalnswsso.cls.janison.com',
  'https://elifesaving.royallifesaving.com.au',
  'https://learning.royallifesaving.com.au',
  'https://josh.canopi.janison.com',
  'https://elearning.royallifesavingwa.com.au',
  'https://engine.canopiplus.com'
]

@Component({
  name: 'App',
  components: {
    Help,
    ClassOutcomes,
    ClassPdfButton
  },
  data: () => ({
    drawer: null,
    mini: true,
    notes: '',
    saveLoading: false
  })
})

export default class App extends Vue {
  get breadcrumbItems () {
    const items = _.filter(this.$store.state.breadcrumbs, (b) => b.text)
    return items
  }

  set breadcrumbItems (value) {
    this.$store.commit('setBreadcrumbs', value)
  }

  get currentClass () {
    return this.$store.state.currentClass
  }

  get dataLoaded () {
    return this.$store.state.dataLoaded
  }
  /*
  itemsData () {
    this.breadcrumbs[1].text = this.$store.state.currentProgram.title
    return this.breadcrumbs
  } */

  @Watch('$route')
  $routeChanged (to: any, from: any) {
    const destName = to.name
    const autoSaveViews = ['Classes', 'Class', 'Lesson']
    const autoSave = autoSaveViews.indexOf(from.name) !== -1
    let crumb = destName
    let index = 0
    switch (destName) {
      case 'Home':
        break
      case 'Classes':
        index = 1
        crumb = {
          text: 'Classes',
          href: '/classes'
        }
        if (this.$store.state.currentProgram) {
          crumb.text = this.$store.state.currentProgram.title
        }
        break
      case 'Class':
        index = 2
        crumb = {
          text: 'Class',
          href: '/class/1'
        }
        if (this.$store.state.currentClass) {
          crumb.text = this.$store.state.currentClass.title
        }
        break
      case 'Lesson':
        index = 3
        crumb = {
          text: 'Class',
          href: '/class/1/lesson/1'
        }
        if (this.$store.state.currentLesson) {
          crumb.text = this.$store.state.currentLesson.title
        }
        break
      default:
        break
    }
    if (index > 0) {
      if (this.$store.state.dataLoaded && autoSave) {
        this.saveData()
        /* let myFirstPromise = new Promise((resolve, reject) => {
          // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
          // In this example, we use setTimeout(...) to simulate async code.
          // In reality, you will probably be using something like XHR or an HTML5 API.
          setTimeout( function() {
            resolve("Success!")  // Yay! Everything went well!
          }, 250)
        }) */
      }
      const menuItems = this.$store.state.breadcrumbs
      menuItems[index] = crumb
      this.breadcrumbItems = menuItems
    }
    // this.$store.dispatch("getProductFromId", this.$route.params.id);
  }

  goBack () {
    this.$router.go(-1)
  }

  saveData () {
    this.$data.saveLoading = true
    const msgData = {
      messageId: 'saveData',
      payload: {
        // configData: this.$store.state.configData,
        // systemData: this.$store.state.systemData,
        // activitiesData: this.$store.state.activitiesData,
        plannerData: this.$store.state.plannerData
      }
    }
    _.forEach(FRAME_ANCESTORS_WHITELIST, (i) => {
      window.parent.postMessage(msgData, i)
      // console.log('iframe sent data to be saved to parent... saving...')
    })
    setTimeout(() => (this.$data.saveLoading = false), 1000)
  }

  sendReadyMessage () {
    const msgData = {
      messageId: 'ready'
    }
    _.forEach(FRAME_ANCESTORS_WHITELIST, (i) => {
      window.parent.postMessage(msgData, i)
      // console.log('iframe told parent it is ready... awaiting reply')
    })
  }

  setExternalDataListener () {
    window.addEventListener('message', this.handleMessages)
  }

  handleMessages (e: any) {
    if (FRAME_ANCESTORS_WHITELIST.indexOf(e.origin) === -1) {
      return
    }
    if (e.data && e.data.payload) {
      // this.sendReadyMessage()
      const dataToLoad = {
        activeProgramId: e.data.payload.activeProgramId || 2, // '2' fallback is to support the original RLS deployment
        configData: e.data.payload.configData,
        systemData: e.data.payload.systemData,
        plannerData: e.data.payload.plannerData,
        activitiesData: e.data.payload.activitiesData
      }
      this.$store.commit('loadAllData', dataToLoad)
      this.loadCustomColors()
      // console.log('Data has been loaded from parent')
    } else {
      console.warn(
        'Data received, but could not be loaded from parent. The structure may be incorrect.' +
          e
      )
    }
  }

  loadCustomColors () {
    if (this.$store.state.dataLoaded) {
      const colors = this.$store.getters.getConfig('colors', {})
      // Light theme
      if (colors.primary) {
        this.$vuetify.theme.themes.light.primary = colors.primary
      }

      // Dark theme
      if (colors.primary) {
        this.$vuetify.theme.themes.dark.primary = colors.primary
      }
    }
  }

  retryLoadData () {
    this.triggerLoadMyData(true)
    this.loadCustomColors()
    if (this.$route.name !== 'Home') {
      this.$router.push('/')
    }
  }

  triggerLoadMyData (loadLocal = false) {
    if (!loadLocal) {
      this.setExternalDataListener()
      this.sendReadyMessage()
    } else {
      // load data from the system as a fallback
      this.$store.dispatch('assembleAndCleanProgramJson')
    }
  }

  beforeMount () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // load data from the system as a fallback
      this.triggerLoadMyData()
    })
    document.title = 'Lesson Planner'
  }
}
