





















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import JsPDF from 'jspdf'
import _, { forEach } from 'lodash'
import moment from 'moment'
import { BreakpointThresholds } from 'node_modules/vuetify/types/services/breakpoint'

@Component({
  name: 'ClassPdfButton',
  data: () => ({
    dialogVisible: false
  })
})
export default class ClassPdfButton extends Vue {
  @Prop() private classItem!: any

  get allowClassPDF () {
    return this.currentClass != null
  }

  get printClassTooltip () {
    return this.allowClassPDF
      ? 'Download Class PDF'
      : 'Download Class PDF only available from Class or Lesson views'
  }

  get currentClass () {
    return this.$store.state.currentClass
  }

  get currentClassExtended () {
    const exClass = this.currentClass
    exClass.startDateFormatted = exClass.startDate
      ? moment(exClass.startDate).format('dddd, Do MMMM YYYY')
      : ''
    exClass.endDateFormatted = exClass.endDate
      ? moment(exClass.endDate).format('dddd, Do MMMM YYYY')
      : ''
    exClass.stageAndMilestoneTitle = this.$store.getters.getStageAndMilestoneTitleOfCurrentClass()
    return exClass
  }

  printNow = function () {
    window.print()
  }

  printClass (isLandscape: boolean) {
    const generatedPDF = this.generatePdf(
      this.currentClassExtended,
      this.$store,
      isLandscape,
      false
    )
    generatedPDF.save('ClassPDF.pdf')
    this.$data.dialogVisible = false
  }

  printClassTest = function () {
    // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
    // Use http://dataurl.net/#dataurlmaker
    const doc = new JsPDF({
      orientation: 'landscape'
    })
    // debugger
    const mdiclipboardlistoutline = `<svg width="24" viewBox="0 0 24 24">
          <rect fill="white" width="24" height="24" />
          <path fill="currentColor" d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M12 17V15H17V17H12M12 11V9H17V11H12M8 12V9H7V8H9V12H8M9.25 14C9.66 14 10 14.34 10 14.75C10 14.95 9.92 15.14 9.79 15.27L8.12 17H10V18H7V17.08L9 15H7V14H9.25" />
      </svg>`
    doc.text('werwe we rwer wer', 50, 50)
    // doc.addSvgAsImage(mdiclipboardlistoutline, 24, 24, 24, 24)
    doc.text('F0004', 150, 150)
    doc.text('mdi-account', 250, 150)
    console.log(doc.getFontList())

    const lessonCard: any = document.querySelector('.lesson-card')
    // const lessonCard: any = 'boo'
    // debugger
    doc.html(lessonCard, {
      callback: function (doc) {
        doc.save()
      },
      x: 10,
      y: 200
    })
    // window.print()
  }

  @Watch('dialogVisible')
  dialogVisibleChanged (newVal: boolean) {
    if (newVal) {
      // window.print()
      // this.printClass()
      // this.generatePdf(this.currentClass, true)
      // const generatedPDF = this.generatePdf(
      //   this.currentClassExtended,
      //   this.$store,
      //   false,
      //   false
      // )
      // generatedPDF.save('ClassPDF.pdf')
    } else {
      // console.log('Dialog was closed!')
    }
  }

  generatePdf = (
    selectedClass: any,
    $store: any,
    isLandscape: boolean,
    jsPDFNoImages: boolean
  ) => {
    const showGrid = false
    const doc = new JsPDF(isLandscape ? 'landscape' : 'portrait')
    /* doc.addFont(
      '/materialdesignicons-webfont.ttf',
      'Material Design Icons',
      'normal'
    ) */
    // doc.setFont('Material Design Icons')

    const a4Height = isLandscape ? 210 : 297
    const a4Width = isLandscape ? 297 : 210
    const leftMargin = isLandscape ? 13 : 10
    const topMargin = isLandscape ? 10 : 13
    const primaryFontSize = 10
    const pageHeight = a4Height - 2 * topMargin
    const pageWidth = a4Width - 2 * leftMargin
    const titleTop = 21
    const bannerHeight = 5
    const headHeight = 20
    const activityHeadHeight = 20
    const maxColCountPerPage = isLandscape ? 3 : 2
    const activityCellHeight = 13
    const lessonGap = 4
    // const metaTop = 33
    // const metaHeight = 22
    const xsPadding = 1
    const smPadding = 2
    const lgPadding = 6
    const bodyHeight = pageHeight - bannerHeight - headHeight
    const bodyWidth = pageWidth
    const lessonCellHeight = bodyHeight - 2 * smPadding
    const lessonHeadHeight = 20
    const col6 = pageWidth / 2
    const col1x = leftMargin + smPadding
    const col2x = col1x + 85 + smPadding
    const col3x = col2x + 99 + smPadding

    const grey: [number, number, number, number?] = [245, 245, 245]
    const white: [number, number, number, number?] = [255, 255, 255]
    const black: [number, number, number, number?] = [0, 0, 0]
    const red: [number, number, number, number?] = [255, 0, 0]
    const primaryColor = (this.$vuetify.theme.currentTheme.primary || '#aaa').toString()
    const primaryColorFaint = (this.$vuetify.theme.currentTheme.tertiary || '#eee').toString()
    let sectionTop = topMargin
    let sectionLeft = leftMargin
    let sectionContentTop = sectionTop
    let pageNo = 1
    let colWidth = bodyWidth
    const pageCount = Math.ceil(
      selectedClass.lessons.length / maxColCountPerPage
    )

    function stripHtmlFilter (text: string) {
      return text
    }

    function splitLines (text: string, width: number, max: number) {
      let lines = text ? doc.splitTextToSize(text, width) : ['']
      if (max && lines.length > max) {
        lines[max - 1] = lines[max - 1] + '...'
        lines = _.head(lines)
      }
      return lines
    }

    // function printActivitySummary (activities, width, x) {
    //   let lines = []
    //   if (activities.length === 1) {
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[0].name), width, 1)
    //     )
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[0].content), width, 6)
    //     )
    //   } else if (activities.length === 2) {
    //     lines.push(activities[0].name);
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[0].name), width, 1)
    //     )
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[0].content), width, 2)
    //     )
    //     lines.push('')
    //     lines.push(activities[1].name);
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[1].name), width, 1)
    //     )
    //     lines = lines.concat(
    //       splitLines(stripHtmlFilter(activities[1].content), width, 2)
    //     )
    //   }
    //   doc.text(x, yOffset, lines)
    // }

    function textBlock (
      text: string,
      x: number,
      y: number,
      width: number,
      max: number
    ) {
      const lines = splitLines(text, width, max)
      doc.text(lines, x, y)
    }

    /* Images
    You'll need to make your image into a Data URL
    Use http://dataurl.net/#dataurlmaker */
    const govLogo = ''
    // if (!jsPDFNoImages) {
    //   const background = 'data:image/jpeg;base64,/9'
    //   doc.addImage(govLogo, 'JPEG', 10, 12, 123.5, 19)
    // } else {
    //   doc.setFontSize(18)
    //   doc.setTextColor.apply(null, black)
    //   doc.text(
    //     'Royal Life Saving NSW',
    //     leftMargin + textPadding,
    //     26
    //   )
    // }

    const renderHeader = function () {
      sectionTop = topMargin
      sectionLeft = leftMargin
      sectionContentTop = sectionTop

      /* Banner bar */
      // doc.rect(
      //   sectionLeft,
      //   sectionTop,
      //   pageWidth,
      //   bannerHeight,
      //   'F'
      // )
      doc.setFontSize(8)
      doc.setTextColor('#999')
      doc.text(
        `Lesson Planner - Page ${pageNo}/${pageCount}`,
        pageWidth + leftMargin - smPadding,
        topMargin + 2 * smPadding,
        { align: 'right' }
      )

      // Move section pointer down on page
      sectionTop += bannerHeight
      sectionLeft += 0

      doc.setDrawColor('#cccccc')
      doc.line(sectionLeft, sectionTop, sectionLeft + pageWidth, sectionTop)

      /* Head Section */
      // doc.setFillColor('white')
      // doc.rect(sectionLeft, sectionTop, pageWidth, headHeight, 'F')
      doc.setTextColor(primaryColor)
      sectionContentTop += bannerHeight + 8
      doc.setFontSize(20)
      textBlock(
        selectedClass.title,
        sectionLeft,
        sectionContentTop,
        pageWidth,
        1
      )
      doc.setTextColor('#666')
      doc.setFontSize(8)
      sectionContentTop += 5
      let dateRangeText = selectedClass.startDateFormatted
      if (
        selectedClass.endDateFormatted &&
        selectedClass.endDateFormatted !== ''
      ) {
        dateRangeText += ` - ${selectedClass.endDateFormatted}`
      }
      textBlock(dateRangeText, sectionLeft, sectionContentTop, pageWidth, 1)
      sectionContentTop += 4
      textBlock(
        `Contains ${selectedClass.lessons.length} lessons`,
        sectionLeft,
        sectionContentTop,
        col6,
        1
      )

      sectionContentTop += 2
      doc.setLineWidth(0.1)
      doc.setDrawColor(primaryColor)
      doc.line(
        sectionLeft,
        sectionContentTop,
        sectionLeft + pageWidth,
        sectionContentTop
      )

      /* if (pageNo > 1) {
        doc.setFillColor('#ccc')
        doc.setTextColor(primaryColor)
        doc.rect(sectionLeft, sectionContentTop, pageWidth, 5, 'F')
        doc.setFontSize(7)
        doc.text(`Page ${'1'}`, pageWidth / 2, sectionContentTop + smPadding, { align: 'center' })
      } */

      sectionContentTop = sectionTop + 7
      doc.setTextColor('#999')
      doc.setFontSize(8)
      textBlock(
        'STAGE & MILESTONE',
        sectionLeft + col6 + smPadding,
        sectionContentTop,
        col6,
        1
      )
      sectionContentTop += 6
      doc.setTextColor(primaryColor)
      doc.setFontSize(14)
      // textBlock('Learn to Swim - Stingray', sectionLeft + col6 + smPadding, sectionContentTop, col6, 1)
      textBlock(
        selectedClass.stageAndMilestoneTitle,
        sectionLeft + col6 + smPadding,
        sectionContentTop,
        col6,
        1
      )
      // Move section pointer down on page
      sectionTop += headHeight
    }

    /* Dark Black Headers */
    doc.setFillColor.apply(null, [0, 0, 255])
    // doc.rect(
    //   leftMarginTableWidthHalf,
    //   titleTop,
    //   titleBlockWidth,
    //   titleHeight,
    //   'F'
    // )

    /* Lines - Horizontal */
    // doc.line(
    //   leftMarginTableWidthHalf + titleBlockWidth,
    //   titleTop + 10,
    //   leftMarginTableWidthHalf + titleBlockWidth + 55,
    //   titleTop + 10
    // )
    // doc.line(leftMargin, 44, leftMargin + 36, 44)
    // doc.line(leftMargin, introTop, leftMargin + tableWidth, introTop)

    /* Lines - Vertical */
    // doc.line(
    //   leftMargin + metaCol1,
    //   metaTop,
    //   leftMargin + metaCol1,
    //   metaTop + metaHeight
    // )
    // doc.line(
    //   leftMargin + metaCol2,
    //   metaTop,
    //   leftMargin + metaCol2,
    //   metaTop + metaHeight
    // )
    // doc.line(
    //   leftMargin + metaCol3,
    //   metaTop,
    //   leftMargin + metaCol3,
    //   metaTop + metaHeight
    // )
    // doc.line(leftCol, tableTop + 39, leftCol, tableTop + 39 + 35)
    // doc.line(rightCol, tableTop + 39, rightCol, tableTop + 39 + 35)
    // doc.line(leftCol, tableTop + 82, leftCol, tableTop + 82 + 27)
    // doc.line(rightCol, tableTop + 82, rightCol, tableTop + 82 + 27)
    // doc.line(
    //   leftMarginTableWidthHalf,
    //   tableTop + 117,
    //   leftMarginTableWidthHalf,
    //   tableTop + 117 + 28
    // )

    renderHeader()

    const renderGrid = function () {
      if (showGrid) {
        doc.setLineWidth(0.1)
        const gap = 5
        _.times(Math.ceil(pageWidth / gap), (index: number) => {
          const xOffset = leftMargin + gap * index
          doc.setDrawColor(index % 10 === 0 ? '#ff0000' : 'pink')
          doc.line(xOffset, topMargin, xOffset, topMargin + pageHeight)
        })
        _.times(Math.ceil(pageHeight / gap), (index: number) => {
          const yOffset = topMargin + gap * index
          doc.setDrawColor(index % 10 === 0 ? '#ff0000' : 'pink')
          doc.line(leftMargin, yOffset, leftMargin + pageWidth, yOffset)
        })
      }
    }

    const getFocusSkill = function (skillId: number): any {
      return $store.getters.getSkillById(skillId) || {}
    }
    const getProgressionLevel = function (progressionLevelId: number): any {
      return $store.getters.getProgressionLevelById(progressionLevelId) || {}
    }

    const calculatedMinutes = (lesson: any) => {
      let mins = 0
      if (lesson.activities) {
        mins = lesson.activities.reduce(function (a: number, b: any) {
          return a + b.duration
        }, 0)
      }
      return mins
    }

    const renderProgressionLevel = function (activity: any, xOffsetCell: number, yOffsetCell: number) {
      const progressionLevel = getProgressionLevel(activity.progressionsLevelId)
      const progressionLevelInt = progressionLevel.order
      doc.setFontSize(4)
      let xOffset = xOffsetCell
      let yOffset = yOffsetCell
      const gap = 0.5
      const dashWidth = 3
      const dashHeight = 0.7
      const placeholderWidth = (3 * dashWidth) + (2 * gap)
      xOffset -= placeholderWidth
      doc.text(
        `${(progressionLevel.title || '').toUpperCase()}`,
        xOffset + (placeholderWidth / 2),
        yOffsetCell,
        { align: 'center' }
      )
      yOffset += 0.8
      _.times(3, (index: number) => {
        xOffset += (index > 0) ? dashWidth + gap : 0
        doc.setFillColor(index + 1 <= progressionLevelInt ? primaryColor : primaryColorFaint)
        doc.rect(xOffset, yOffset, dashWidth, dashHeight, 'F')
      })
    }

    /* Render page body */

    doc.setFillColor('white') // Body rect
    doc.rect(sectionLeft, sectionTop, bodyWidth, bodyHeight, 'F')

    const lessons = selectedClass.lessons
    let colsPerPage = lessons.length
    if (colsPerPage > 1 && colsPerPage <= maxColCountPerPage) {
      colWidth = (bodyWidth - lessonGap * (colsPerPage - 1)) / colsPerPage
    } else if (colsPerPage > maxColCountPerPage) {
      colsPerPage = maxColCountPerPage
      colWidth = (bodyWidth - lessonGap * (colsPerPage - 1)) / colsPerPage
    }

    const radius = 5
    const diameter = 2 * radius

    _.each(lessons, (l: any, index: number) => {
      const itemNumber = index % maxColCountPerPage
      let xOffsetCol =
        sectionLeft + colWidth * itemNumber + lessonGap * itemNumber
      let yOffsetCol = sectionTop + smPadding
      if (index > 1 && itemNumber === 0) {
        renderGrid()
        doc.addPage()
        pageNo++
        renderHeader()
      }
      doc.setLineWidth(0.1)
      doc.setDrawColor('#cccccc')
      doc.setFillColor('white')
      doc.roundedRect(
        xOffsetCol,
        yOffsetCol,
        colWidth,
        lessonCellHeight,
        1,
        1,
        'FD'
      )
      doc.setFillColor('#eee')
      doc.roundedRect(
        xOffsetCol,
        yOffsetCol,
        colWidth,
        lessonHeadHeight - 1,
        1,
        1,
        'FD'
      )
      doc.rect(
        xOffsetCol + 0.1,
        yOffsetCol + 2,
        colWidth - 0.2,
        lessonHeadHeight - 2,
        'F'
      )
      doc.setFillColor(primaryColor)
      xOffsetCol += smPadding
      yOffsetCol += smPadding
      doc.ellipse(xOffsetCol + radius, yOffsetCol + radius, radius, radius, 'F')
      doc.setTextColor('white')
      doc.setFontSize(12)
      // yOffset += radius + smPadding
      doc.text(
        `L${index + 1}`,
        xOffsetCol + radius,
        yOffsetCol + radius + smPadding,
        { align: 'center' }
      )
      yOffsetCol = sectionTop + 3 * smPadding
      doc.setTextColor('#999')
      doc.setFontSize(8)
      doc.text('LESSON AIM', xOffsetCol + diameter + smPadding, yOffsetCol)
      doc.setTextColor('#999')
      const focusSkill = getFocusSkill(l.focusSkill)
      doc.setTextColor(primaryColor)
      doc.setFontSize(11)
      doc.text(
        focusSkill.title,
        xOffsetCol + diameter + smPadding,
        yOffsetCol + lgPadding
      )
      yOffsetCol += diameter + 2 * smPadding
      doc.setTextColor('#666')
      doc.setFontSize(9)
      doc.text(`${l.activities.length} Activities`, xOffsetCol, yOffsetCol)
      doc.text(
        `${calculatedMinutes(l)} Minutes`,
        xOffsetCol + colWidth - 2 * smPadding,
        yOffsetCol,
        { align: 'right' }
      )

      // sectionLeft = yOffsetCol + smPadding

      const cellWidth = colWidth - 2 * xsPadding
      let xOffsetCell =
        sectionLeft + colWidth * itemNumber + lessonGap * itemNumber + xsPadding
      let yOffsetCell = sectionTop + activityHeadHeight + smPadding
      // Activity rendering
      _.each(l.activities, (a: any, index: number) => {
        yOffsetCell += xsPadding
        doc.setDrawColor('#cccccc')
        doc.setFillColor('white')
        doc.roundedRect(
          xOffsetCell,
          yOffsetCell,
          cellWidth,
          activityCellHeight,
          1,
          1,
          'FD'
        )
        doc.setTextColor('#666')
        doc.setFontSize(primaryFontSize)
        xOffsetCell += 0
        textBlock(
          a.title,
          xOffsetCell + xsPadding,
          yOffsetCell + 5 * xsPadding,
          colWidth - 6 * smPadding,
          2
        )
        doc.setFontSize(14)
        doc.text(
          `${a.duration}`,
          xOffsetCell + colWidth - (xsPadding + smPadding) - 5,
          yOffsetCell + 3 * smPadding,
          { align: 'center' }
        )
        renderProgressionLevel(a, xOffsetCell + colWidth - (xsPadding + smPadding), yOffsetCell + (5 * smPadding))
        yOffsetCell += activityCellHeight
      })
    })

    /* -- */

    /* -- */

    /* -- */

    // doc.setFontSize(11)
    // doc.setTextColor.apply(null, white)
    // doc.text(col1x, row1 + rowPadding, 'START OUT')
    // doc.text(col2x, row1 + rowPadding, 'GET INTO IT')
    // doc.text(col3x, row1 + rowPadding, 'FINISH UP')

    // doc.setFontSize(9)
    // doc.setTextColor.apply(null, black)
    // doc.text(col1x, row1 + rowPaddingContent, 'List activities & duration')
    // doc.text(col2x, row1 + rowPaddingContent, 'List activities & duration')
    // doc.text(col3x, row1 + rowPaddingContent, 'List activities & duration')

    // --

    // doc.setFontSize(11)
    // doc.setTextColor.apply(null, white)
    // doc.text(col1x, row2 + rowPadding, 'DELIVERY')

    // doc.setFontSize(8)
    // doc.setTextColor.apply(null, white)
    // doc.text(
    //   col1x + 21,
    //   row2 + rowPadding,
    //   'How the content above will be delivered e.g. Formations, groups, circuits, activity combinations, sequences, transitions. Also include modifications to activities, questions to ask, coaching tips.'
    // )

    // doc.setFontSize(9)
    // doc.setTextColor.apply(null, black)
    // doc.text(col1x, row2 + rowPaddingContent, 'START OUT')
    // doc.text(col2x, row2 + rowPaddingContent, 'GET INTO IT')
    // doc.text(col3x, row2 + rowPaddingContent, 'FINISH UP')

    // --

    /* Dynamic text */
    doc.setFontSize(10)
    doc.setTextColor.apply(null, black)

    /* Meta */
    // const sessionPlan = selectedClass.data.sessionPlan
    // textBlock(sessionPlan.duration, col1x, metaTop + 20, 32, 1)
    // textBlock(sessionPlan.environment, col1x + 36, metaTop + 9, 48, 4)
    // textBlock(sessionPlan.equipment, col1x + 142, metaTop + 9, 130, 4)
    // textBlock(sessionPlan.objectives, col1x + 44, tableTop + 5, 220, 3)

    /* Selected activities */
    // yOffset = row1 + thHeight + cellPaddingTop
    // printActivitySummary(selectedActivities.warmup, 85, col1x)
    // printActivitySummary(selectedActivities.core, 95, col2x)
    // printActivitySummary(selectedActivities.cooldown, 85, col3x)

    /* Delivery notes */
    // yOffset = row2 + thHeight + cellPaddingTop
    // textBlock(sessionPlan.delivery.warmup, col1x, yOffset, 85, 5)
    // textBlock(sessionPlan.delivery.core, col2x, yOffset, 95, 5)
    // textBlock(sessionPlan.delivery.cooldown, col3x, yOffset, 85, 5)

    /* Notes */
    // yOffset = row3 + thHeight + cellPaddingTop
    // textBlock(sessionPlan.safety, col1x, yOffset, 140, 5)

    /* Activity Card Images */
    // const allActivities = _.reduce(
    //   selectedActivities,
    //   function (list, phase) {
    //     return list.concat(phase)
    //   },
    //   []
    // )
    // if (!jsPDFNoImages) {
    //   _(allActivities)
    //     .where('imageData')
    //     .each(function (activity) {
    //       doc.addPage()
    //       doc.addImage(activity.imageData, 'JPEG', 0, 0, 297, 210)
    //     })
    // }

    renderGrid()
    return doc
  }
}
