/* eslint @typescript-eslint/no-var-requires: "off" */

import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import AppConfigData from '@/data/appconfig.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    devMode: false,
    programId: null,
    config: null,
    systemData: null,
    plannerData: null,
    dataLoaded: false,
    programData: null,
    currentProgram: null,
    currentClass: null,
    currentLesson: null,
    currentActivity: null,
    breadcrumbs: [
      {
        text: 'Home',
        href: '/'
      },
      {},
      {},
      {}
    ]
  },
  getters: {
    getAppVersion: (state: any) => () => {
      return '1.3.4'
    },
    getConfig: (state: any) => (configKey: string, defaultValue = null) => {
      if (!state.config) {
        return defaultValue
      }
      return state.config[configKey] || defaultValue
    },
    getPlannerDataVersion: (state: any) => () => {
      return state.plannerData.dataVersion
    },
    getSystemDataVersion: (state: any) => () => {
      return state.systemData.dataVersion
    },
    getProgramDefById: (state: any, getters) => (id: number) => {
      return getters.programs().find((item: any) => item.id === id)
    },
    getPlannerDataByProgramId: (state: any) => (id: number) => {
      return state.plannerData.programs.find((item: any) => item.id === id)
    },
    getClassById: (state: any) => (id: number) => {
      if (!state.currentProgram) {
        return null
      }
      return state.currentProgram.classes.find((item: any) => item.id === id)
    },
    getLessonById: (state: any) => (id: number) => {
      if (!state.currentClass) {
        return null
      }
      return state.currentClass.lessons.find((item: any) => item.id === id)
    },
    getActivityById: (state: any) => (id: number) => {
      return state.currentLesson.activities.find((item: any) => item.id === id)
    },
    getDevStageById: (state: any, getters) => (id: number) => {
      return getters.getStages().find((item: any) => item.id === id)
    },
    getStageByMilestoneId: (state: any, getters) => (id: number) => {
      // debugger
      const stageId = getters.getDevMilestoneById(id).stageId
      return getters.getDevStageById(stageId)
    },
    getDevMilestoneById: (state: any, getters) => (id: number) => {
      return getters.getMilestones().find((item: any) => item.id === id)
    },
    getStrandById: (state: any, getters) => (id: number) => {
      return getters.getStrands().find((item: any) => item.id === id)
    },
    getSkillById: (state: any) => (id: number) => {
      return state.programData.skills.find((item: any) => item.id === id)
    },
    getProgressionLevelById: (state: any, getters) => (id: number) => {
      return getters.progressionLevels().find((item: any) => item.id === id)
    },
    programs: (state: any) => () => {
      if (
        state &&
        state.systemData &&
        state.systemData.programs
      ) {
        return state.systemData.programs
      }
      return []
    },
    getStrands: (state: any) => () => {
      let strands = []
      if (
        state.programData &&
        state.programData.strands
      ) {
        strands = state.programData.strands
      }
      return _.sortBy(strands, ['order'])
    },
    getMilestones: (state: any, getters) => () => {
      let milestones = []
      if (
        state.programData &&
        state.programData.stages
      ) {
        const items: any[] = []
        _.each(getters.getStages(), s => {
          const milestones = _.map(s.milestones, m => {
            m.stageId = s.id
            return m
          })
          items.push(...milestones)
        })
        milestones = items
      }
      return _.sortBy(milestones, ['order'])
    },
    getStages: (state: any) => () => {
      let stages = []
      if (
        state.programData &&
        state.programData.stages
      ) {
        stages = state.programData.stages
      }
      return _.sortBy(stages, ['order'])
    },
    progressionLevels: (state: any) => () => {
      let progressionLevels = []
      if (
        state.programData &&
        state.programData.progressionLevels
      ) {
        progressionLevels = state.programData.progressionLevels
      }
      return _.sortBy(progressionLevels, ['order'])
    },
    getStagesWithMilestones: (state: any, getters) => () => {
      const items: any[] = []
      _.each(getters.getStages(), s => {
        const milestones = _.map(s.milestones, m => {
          return {
            text: m.title,
            stageTitle: s.title,
            title: `${s.title} - ${m.title}`,
            id: m.id,
            disabled: m.disabled,
            stageId: s.id
            // selected: s.id === this.selectedMilestoneId
          }
        })
        items.push({
          header: s.title
        })
        items.push(...milestones)
      })
      return items
    },
    getStageAndMilestoneTitle: (state: any, getters) => (milestoneId: any) => {
      const titles = []
      if (!milestoneId) {
        return ''
      }
      const stage = getters.getStageByMilestoneId(milestoneId)
      if (stage && stage.title) {
        titles.push(stage.title)
      }
      const milestone = getters.getDevMilestoneById(milestoneId)
      if (milestone && milestone.title) {
        titles.push(milestone.title)
      }
      const title = _.join(titles.filter(i => i !== ''), ' - ')
      return title
    },
    getStageAndMilestoneTitleOfCurrentClass: (state: any, getters) => () => {
      if (!(state.currentClass && state.currentClass.milestoneId)) {
        return ''
      }
      return getters.getStageAndMilestoneTitle(state.currentClass.milestoneId)
    },
    getStageOfCurrentClass: (state: any, getters) => () => {
      if (!state.currentClass) {
        return
      }
      return getters.getDevStageById(state.currentClass.stageId)
    },
    getMilestoneOfCurrentClass: (state: any, getters) => () => {
      if (!state.currentClass) {
        return
      }
      return getters.getDevMilestoneById(state.currentClass.milestoneId)
    },
    getStrandDevMilestones: (state: any, getters) => () => {
      const programDef = getters.getProgramDefById(state.programId)
      // Get all the Milestones, group them by stageId to inject into the respective Stage's array.
      const devStrandGroups = _.chain(programDef.strandDevelopmentMilestones)
        .groupBy('milestoneId')
        .groupBy('stageId').value()

      // debugger

      const items = _.map(programDef.stages, s => {
        const milestones = _.map(s.milestones, m => {
          const strandDevMilestones = _.map(programDef.strands, str => {
            const strandMilestone = _.find(programDef.strandDevelopmentMilestones, x => {
              return m.id === x.milestoneId && str.id === x.strandId
            })
            const strandMilestoneObj = _.cloneDeep(strandMilestone || {})
            const strandObj = _.cloneDeep(_.find(programDef.strands, s => s.id === strandMilestoneObj.strandId) || {})
            const milestoneObj = _.cloneDeep(_.find(getters.getMilestones(), m => m.id === strandMilestoneObj.milestoneId) || {})
            const newItem = _.assign(strandMilestoneObj, {
              strand: strandObj,
              milestone: milestoneObj
            })
            return newItem
          })
          return _.assign(m, {
            strandMilestones: strandDevMilestones
          })
        })
        return _.assign(s, {
          milestones: milestones
        })
      })
      return _.chain(items).flatMap(i => {
        return i.milestones
      }).sortBy(['order']).value()
    }
  },
  mutations: {
    loadAllData (state: any, payload: any) {
      // First point that programId gets loaded, so get it from Config
      if (!payload.activeProgramId) {
        console.log('Failed to load data, activeProgramId is null.')
        return
      }
      const activeProgramId = payload.activeProgramId
      state.programId = activeProgramId

      if (!activeProgramId) {
        // We don't have a programId so fail to load
        return
      }
      const emptyPlannerData = {
        dataVersion: '1',
        programs: [
          {
            id: activeProgramId,
            classes: []
          }
        ]
      }

      if (!payload.plannerData) {
        payload.plannerData = emptyPlannerData
      }
      if (payload.plannerData.programs[0] && payload.plannerData.programs[0].id !== activeProgramId) {
        // if old data and it has activeProgramId of 1 instaed of 2 for rlsnsw
        payload.plannerData.programs[0].id = activeProgramId
      }
      // update stage and milestone to be stageId and milestoneId properties on Classes
      if (payload.plannerData.programs[0]) {
        // if old data and it has activeProgramId of 1 instaed of 2 for rlsnsw
        // TODO: We need an if blocker, and to update the data version here to ensure this error gets upgraded
        _.map(payload.plannerData.programs[0].classes, c => {
          c.stageId = c.stageId || (c.stage ? c.stage.id : null)
          c.milestoneId = c.milestoneId || (c.milestone ? c.milestone.id : null)
          c.stage = null
          c.milestone = null
          return c
        })
      }
      try {
        state.config = payload.configData
        state.systemData = payload.systemData
        state.plannerData = payload.plannerData
        state.programData = payload.systemData.programs[0]
        state.programData.activities = payload.activitiesData
        state.currentProgram = payload.plannerData.programs[0]
        state.dataLoaded = true
      } catch (error) {
        state.dataLoaded = false
      }
    },
    setSystemDataXXX (state: any, payload) { // not really being used...
      if (
        payload &&
        payload.systemData
      ) {
        state.systemData = payload.systemData
        if (
          payload.systemData.programs &&
          payload.systemData.programs.length > 0
        ) {
          state.programData = payload.systemData.programs[0]
          state.programData.activities = payload.activitiesData
        }
      }
      if (
        // !state.plannerData &&
        payload &&
        payload.plannerData
      ) {
        state.plannerData = payload.plannerData
      }
      if (
        // !state.currentProgram &&
        payload &&
        payload.plannerData &&
        payload.plannerData.programs &&
        payload.plannerData.programs.length > 0
      ) {
        state.currentProgram = payload.plannerData.programs[0]
      }
    },
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    },
    setCurrentProgram (state, payload) {
      state.currentProgram = payload
      state.currentClass = null
      state.currentLesson = null
      state.currentActivity = null
    },
    setCurrentClass (state, payload) {
      state.currentClass = payload
      state.currentLesson = null
      state.currentActivity = null
    },
    setCurrentLesson (state, payload) {
      state.currentLesson = payload
      state.currentActivity = null
    },
    setCurrentActivity (state, payload) {
      const newItem = _.cloneDeep(payload)
      state.currentActivity = newItem
    },
    resetCurrentActivity (state) {
      state.currentActivity = null
    },
    createClassInCurrentProgram (state: any, payload) {
      const maxIdItem = _.maxBy(state.currentProgram.classes, (x: any) => x.id)
      const maxId = maxIdItem ? maxIdItem.id : 0
      const newItem = _.assign({
        id: maxId + 1,
        lessons: []
      }, payload)
      state.currentProgram.classes.push(newItem)
      // return newItem.id
    },
    removeClassFromCurrentProgram (state: any, payload) {
      state.currentProgram.classes = _.filter(state.currentProgram.classes, x => x.id !== payload.id)
    },
    createLessonInCurrentClass (state: any, payload) {
      const maxIdItem = _.maxBy(state.currentClass.lessons, (x: any) => x.id)
      const maxId = maxIdItem ? maxIdItem.id : 0
      const newItem = _.assign({
        id: maxId + 1,
        activities: []
      }, payload)
      state.currentClass.lessons.push(newItem)
    },
    removeLessonFromCurrentClass (state: any, payload) {
      state.currentClass.lessons = _.filter(state.currentClass.lessons, x => x.id !== payload.id)
    },
    addActivityToCurrentLesson (state: any, payload) {
      const maxIdItem = _.maxBy(state.currentLesson.activities, (x: any) => x.id)
      const maxId = maxIdItem ? maxIdItem.id : 0
      const newItem = _.assign(payload, {
        id: maxId + 1,
        definitionId: payload.id
      })
      state.currentLesson.activities.push(newItem)
    },
    removeActivityFromCurrentLesson (state: any, payload) {
      state.currentLesson.activities = _.filter(state.currentLesson.activities, x => x.id !== payload.id)
      // state.currentLesson.activities = _.filter(state.currentLesson.activities, x => x.definitionId !== payload.definitionId)
    },
    updateActivityInCurrentLesson (state: any, payload) {
      const newItem = _.cloneDeep(payload)
      const newItems = [
        newItem
      ]
      state.currentLesson.activities = state.currentLesson.activities.map((x: any) => {
        const matchItem = newItems.find(o => o.id === x.id)
        // const matchItem = newItems.find(o => o.definitionId === x.definitionId)
        return matchItem || x
      })
    },
    updateLesson (state: any, payload) {
      const newItem = _.cloneDeep(payload)
      const newItems = [
        newItem
      ]
      state.currentLesson = newItem
      state.currentClass.lessons = state.currentClass.lessons.map((x: any) => {
        const matchItem = newItems.find(o => o.id === x.id)
        return matchItem || x
      })
    },
    updateClass (state: any, payload) {
      const newItem = _.cloneDeep(payload)
      const newItems = [
        newItem
      ]
      state.currentClass = newItem
      state.currentProgram.classes = state.currentProgram.classes.map((x: any) => {
        const matchItem = newItems.find(o => o.id === x.id)
        return matchItem || x
      })
    },
    deleteClass (state: any, payload) {
      state.currentClass = null
      state.currentProgram.classes = _.filter(state.currentProgram.classes, o => o.id !== payload.id)
      state.currentLesson = null
    },
    deleteLesson (state: any, payload) {
      state.currentLesson = null
      state.currentClass.lessons = _.filter(state.currentClass.lessons, o => o.id !== payload.id)
      _.each(state.currentClass.lessons, (l, i) => {
        l.id = i + 1
      })
    }
  },
  actions: {
    assembleAndCleanProgramJson (context) {
      const appConfigData = _.cloneDeep(AppConfigData)
      // First point that programId gets loaded, so get it from Config
      if (!appConfigData) {
        return
      }
      const activeProgramId = appConfigData.activeProgramId
      if (!activeProgramId) {
        // We don't have a programId so fail to load
        return
      }
      const ConfigData = require(`@/data/${activeProgramId}/config.json`)
      const SamplePlannerData = require(`@/data/${activeProgramId}/sample_planner.json`)
      const ProgramData = require(`@/data/${activeProgramId}/programs.json`)
      // import OwnersData from '@/data/owners.json'
      const ProgressionLevelsData = require(`@/data/${activeProgramId}/progression_levels.json`)
      // import LocationsData from '@/data/locations.json'
      // import ActivityLocations from '@/data/activity_locations.json'
      const MilestonesData = require(`@/data/${activeProgramId}/milestones.json`)
      const SkillsData = require(`@/data/${activeProgramId}/skills.json`)
      const StagesData = require(`@/data/${activeProgramId}/stages.json`)
      const StrandsData = require(`@/data/${activeProgramId}/strands.json`)
      const ResourcesData = require(`@/data/${activeProgramId}/resources.json`)
      const ActivitiesData = require(`@/data/${activeProgramId}/activities.json`)
      const StrandDevMilestonesData = require(`@/data/${activeProgramId}/strand_dev_milestones.json`)

      const systemDefinition: any = {
        dataVersion: 1,
        programs: []
      }
      let programDefinition: any
      let itemsToMerge
      let allItems

      if (ProgramData.id === activeProgramId) {
        programDefinition = ProgramData
      }

      if (!programDefinition) {
        // Cannot locate Program so fail to load
        console.error(`Could not load program definition for id ${activeProgramId}`)
        return
      }

      allItems = ProgressionLevelsData
      itemsToMerge = _.filter(allItems, (item: any) => item.programId === activeProgramId)
        .map((i) => _.omit(i, ['programId']))
      programDefinition.progressionLevels = itemsToMerge

      // Get all the Milestones, group them by stageId to inject into the respective Stage's array.
      const milestoneGroups = _.chain(MilestonesData)
        .filter((item: any) => item.programId === activeProgramId)
        .groupBy('stageId').value()

      // Get all the Stages, and inject the milestones.
      allItems = StagesData
      itemsToMerge = _.chain(allItems).filter((item: any) => item.programId === activeProgramId)
        .map((stage: any) => {
          const milestones = _.chain(milestoneGroups)
            .get(stage.id, [])
            .map((a) => _.omit(a, ['stageId']))
            .value()
          stage.milestones = milestones
          return stage
        }).value()
      programDefinition.stages = itemsToMerge

      allItems = SkillsData
      itemsToMerge = _.filter(allItems, (item: any) => item.programId === activeProgramId)
      programDefinition.skills = itemsToMerge

      allItems = StrandsData
      itemsToMerge = _.filter(allItems, (item: any) => item.programId === activeProgramId)
      programDefinition.strands = itemsToMerge

      // Get all the Resources, group them by activityId to inject into the respective Activity's array.
      const resourceGroups = _.chain(ResourcesData)
        .groupBy('activityId').value()

      // Get all the Activities, and inject the resources.
      allItems = ActivitiesData
      itemsToMerge = _.chain(allItems).filter((item: any) => item.programId === activeProgramId)
        .map((activity: any) => {
          const resources = _.chain(resourceGroups)
            .get(activity.id, [])
            .map((a) => _.omit(a, ['activityId', 'id']))
            .value()
          activity.resources = _.sortBy(resources, [(r) => {
            return r.fileName.indexOf('mp4') !== -1
          }, 'fileName'])
          return activity
        }).value()
      programDefinition.activities = itemsToMerge

      allItems = StrandDevMilestonesData
      itemsToMerge = _.filter(allItems, (item: any) => item.programId === activeProgramId)
      programDefinition.strandDevelopmentMilestones = itemsToMerge

      // - owners: this.$store.state.tempNotesData,
      // * programs: this.$store.state.tempNotesData,
      // * progressionLevels: this.$store.state.tempNotesData,
      // - locations: this.$store.state.tempNotesData,
      // - // activityLocations: this.$store.state.tempNotesData,
      // milestones: this.$store.state.tempNotesData,
      // skills: this.$store.state.tempNotesData,
      // stages: this.$store.state.tempNotesData,
      // strands: this.$store.state.tempNotesData,
      // activities: this.$store.state.tempNotesData,
      // strandDevelopmentMilestones: this.$store.state.tempNotesData

      systemDefinition.programs.push(programDefinition)

      const systemData = _.cloneDeep(systemDefinition)
      let startingPlannerData = null
      if (ConfigData.devMode) {
        startingPlannerData = _.cloneDeep(SamplePlannerData)
      }

      const dataToLoad = {
        activeProgramId: activeProgramId,
        configData: ConfigData,
        systemData: systemData,
        plannerData: startingPlannerData,
        activitiesData: systemData.programs[0].activities
      }
      context.commit('loadAllData', dataToLoad)
    }
  },
  plugins: [
    (store) => {
      /* window.addEventListener('gamepadconnected', async (e) => {
        await store.dispatch('destroyAllHumans', true)
        store.commit('markAsDestroyed', true)
        console.log(`If this is ${store.state.humans_gone}, then I am all done`)
      }) */
      window.addEventListener(
        'message',
        function (r) {
          const targetDomain = 'https://lesson-planner.netlify.app'
          const targetDomain2 = 'https://josh.canopi.janison.com'
          if (r.origin !== targetDomain && r.origin !== targetDomain2) {
            return
          }
          console.log(r.data)
          store.commit('setCurrentNotes', r.data)
        },
        false
      )
    }
  ],
  modules: {}
})
