































































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Help',
  data: () => ({
    dialogVisible: false
  })
})

export default class Help extends Vue {
  get appVersion (): string {
    return this.$store.getters.getAppVersion()
  }
}
