import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        // primary: '#002664',
        // secondary: '#5CB2C1',
        // tertiary: '#C2D9FF',
        // accent: '#163064',
        // error: '#C2160A',
        // warning: '#ff9800',
        // info: '#D0D2C6',
        // success: '#3E8E41'
        primary: '#2C61C8',
        secondary: '#5CB2C1',
        tertiary: '#C2D9FF',
        accent: '#163064',
        error: '#C2160A',
        warning: '#ff9800',
        info: '#D0D2C6',
        success: '#3E8E41'
      },
      dark: {
        primary: '#2C61C8',
        secondary: '#5CB2C1',
        tertiary: '#C2D9FF',
        accent: '#163064',
        error: '#C2160A',
        warning: '#ff9800',
        info: '#D0D2C6',
        success: '#3E8E41'
      }
    }
  }
})
